import React from 'react';
import './About.css'

const about = (props) => {

	return (
		<main className="about">
			<h1>We are attorneys dedicated to helping our clients make art and tell stories.</h1>
			<div className="description">
				<div>
					<span className="company">Patrick+McCormack</span> is a full-service transactional law firm. Founded in 2008, it offers high-quality, pragmatic solutions to clients across the motion picture, television, publishing, and media industries.
				</div>
			</div>
			<div className="recognition">
				<h2>Recognition</h2>
				<ul>
					<li><span className="plus">+</span> SuperLawyers, 2020 - present</li>
					<li><span className="plus">+</span> Best Lawyers, 2021 - present</li>
					<li><span className="plus">+</span> US News and World Report Best Law Firms for Entertainment Law (tier 1, nationally), 2022 - present</li>
				</ul>
			</div>
		</main>
	);
};

export default about;
